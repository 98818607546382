.partners {
    width: 100%;
    height: 900px;
}

.partners_group {
    display: flex;
    gap: 20px;
}

.part_1,
.part_2,
.part_3 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.partners_img_right {
    border-radius: 60px 0px 60px 0px;
    box-shadow: 0px 0px 80px 40px #18ce1541;
    animation: partners_right 3s ease-in-out;
    z-index: 9999;
    padding: 170px 20px 30px 0;
}

.partners_img_right>div {
    border-bottom-right-radius: 60px;
}

.partners_img_left {
    border-radius: 0px 60px 0px 60px;
    box-shadow: 0px 0px 80px 40px #18ce1541;
    animation: partners_left 3s ease-in-out;
    z-index: 9999;
    padding: 170px 0 30px 20px;
}

.partners_img_left>div {
    border-bottom-left-radius: 60px;
}

.partners_img_logo {
    width: 140px;
    animation: partners_logo 6.5s ease-in-out;
}

@keyframes partners_logo {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.partners_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    gap: 50px;
}

.partners_content>h1 {
    color: #19CE15;
    font-family: 'global-font';
    font-size: 64px;
    font-weight: 400;
    letter-spacing: 1px;
    animation: partners_h1 3s ease-in-out;
    text-align: center;
}

@keyframes partners_h1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.partners_content>div {
    display: flex;
    gap: 25px;
}

.part {
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 800ms linear;
}

.part_7 {
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 800ms linear;
    animation: partners_right 3s ease-in-out;
}

.part:hover {
    transform: scale(1.2);
}

.part_7:hover {
    transform: scale(1.2);
}

.part>div {
    color: white;
    padding: 15px;
    font-size: 12px;
    font-weight: bolder;
    letter-spacing: 1px;
}

.part_7>div {
    color: white;
    padding: 15px;
    font-size: 12px;
    font-weight: bolder;
    letter-spacing: 1px;
}

.p1 {
    background-color: #49354187;
}

.p2 {
    background-color: #016FC775;
}

.p3 {
    background-color: #19CE1561;
}

.p4 {
    background-color: #00D2E287;
}

.p5 {
    background-color: #00D2E287;
}

.p6 {
    background-color: #19CE1599;
}

.p7 {
    background-color: #201F1F80;
}

@keyframes partners_left {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes partners_right {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* --------------------------------------response---------------------------------------------------------- */
@media screen and (max-width:570px) {
    .part {
        width: 70px;
        height: 100px;
        overflow: hidden;
    }

    .part_7 {
        width: 70px;
        height: 100px;
        overflow: hidden;
    }

    .partners_img_right {
        border-radius: 80px 0px 80px 0px;
        padding: 60px 0 0 0;
    }

    .partners_img_left {
        border-radius: 80px 0px 80px 0px;
        padding: 60px 0 0 0;
    }

    .partners_img_logo {
        width: 90px;
    }

    .partners_content>h1 {
        font-size: 48px;
    }

    .partners {
        height: 500px;
    }

    .part>div {
        color: white;
        padding: 4px 0 4px 5px;
        font-size: 4px;
    }

    .part_7>div {
        color: white;
        padding: 4px 0 4px 5px;
        font-size: 4px;
    }

    .partners_content>div {
        display: flex;
        gap: 10px;
    }

    .partners_group {
        display: flex;
        gap: 10px;
    }

    .part>div>p {
        width: 50px;
    }

    .part_7>div>p {
        width: 50px;
    }
}

@media screen and (min-width:571px) and (max-width: 880px) {

    .partners_img_logo {
        width: 90px;
    }

    .part {
        width: 120px;
        padding: 160px 20px 0 0;
        background-size: cover;
    }

    .part_7 {
        width: 120px;
        padding: 160px 20px 0 0;
        background-size: cover;
    }

    .part>div {
        font-size: 8px;
        text-align: center;
    }

    .partners_img_right {
        border-radius: 80px 0px 80px 0px;
        padding: 80px 10px 20px 0;
    }

    .partners_img_left {
        border-radius: 0px 80px 0px 80px;
        padding: 80px 0 20px 10px;
    }

    .partners_content>h1 {
        font-size: 48px;
    }

    .partners {
        height: 500px;
    }
}