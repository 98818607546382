.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
}

.timer>h1 {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.timer_content>div>span:first-child {
    font-size: 30px;
    font-weight: bold;
}

.timer_content>div>span:last-child {
    font-size: 14px;
    font-weight: bolder;
}

.timer_content>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer_content {
    display: flex;
    justify-content: center;
    gap: 15px;
}