.footer_contact {
    width: 100%;
}

.footer_contact_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 200px 0 50px 0;
}

.contact_img {
    max-width: 360px;
    position: absolute;
    top: 0px;
    left: 20px;
}

.contact {
    background: linear-gradient(to top, #19CE1521 50%, #37373780 50%);
    width: 912px;
    height: 370px;
    position: relative;
}

.social_pages {
    display: flex;
    gap: 20px;
}

.social_pages>a {
    border: 1px solid #19CE15;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.social_pages>a>svg {
    width: 30px;
    height: 30px;
}

.social_pages>a>svg>path:hover {
    fill: #19CE15;
    transition: fill 800ms linear;
}

.contact>h1 {
    color: #19CE15;
    font-size: 64px;
    font-weight: 400;
    font-family: 'global-font';
    position: absolute;
    top: -50px;
    right: 350px;
    animation: contact_h1 1.5s ease-in-out;
}

@keyframes contact_h1 {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* --------------------------------------response---------------------------------------------------------- */
@media screen and (max-width:430px) {
    .contact {
        width: 100%;
        height: 500px;
    }

    .contact_img {
        display: none;
    }

    .footer_contact {
        padding: 20px;
    }

    .contact>h1 {
        top: -40px;
        right: 160px;
        font-size: 48px;
    }
    
}

@media screen and (min-width:431px) and (max-width:940px) {
    .contact {
        width: 410px;
        height: 400px;
    }

    .contact_img {
        display: none;
    }

    .contact>h1 {
        text-align: center;
        top: -50px;
        right: 230px;
        font-size: 48px
    }

    .footer_contact {
        padding: 20px;
    }
}