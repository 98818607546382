.main_about_us {
    width: 100%;
    height: 360px;
    margin: 50px 0 500px 0;
}

.text_invitation>h1 {
    color: #19CE15;
    font-family: 'global-font';
    font-size: 64px;
    font-weight: 100;
    position: absolute;
    left: 250px;
    top: -50px;
    animation: invitation_h1 1.5s ease-in-out;
}

.text_invitation_content{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    font-size: 20px;
}

/* .special{
    min-width: 470px;
} */
@keyframes invitation_h1 {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.back {
    width: 1200px;
    height: 800px;
    background-color: #2D3331A1;
    z-index: -1;
    animation: back 1.5s ease-in-out;
    /* display: flex;
    justify-content: center; */
    /* align-items: flex-end; */
    padding: 25px;
    position: relative;
}

@keyframes back {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.main_about_us_content {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
}

.relax_project {
    max-width: 350px;
    position: absolute;
    top: -150px;
    right: 220px;
    animation: relax 1.5s ease-in-out;
}

@keyframes relax {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.text {
    background-color: #136F1133;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 20px;
    gap: 20px;
    top: 85px;
}

.find {
    width: 300px;
    height: 50px;
    background-color: transparent;
    color: white;
    background-color: #19CE15;
    border: none;
    font-size: 20px;
    cursor: pointer;
    animation: centre 3s linear infinite;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -150px;

}

.find:hover {
    color: white;
    background-color: #19CE15;
    transition: background-color 700ms linear;
}

.text_data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 120px;
}

.text_data_1,
.text_data_2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.text_data_1>div,
.text_data_2>div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: white;
}

.text_data_1>div>span,
.text_data_2>div>span {
    letter-spacing: 1px;
    font-size: 18px;
}

.text_invitation_content>p {
    color: white;
    max-width: 900px;
    font-size: 20px;
}

.special {
    color: white;
    font-size: 20px;
    margin: 0 5px 0 5px;
}

/* --------------------------------------response---------------------------------------------------------- */

@media screen and (max-width:600px) {
    .back {
        height: 1100px;
        padding: 25px;
        width: 100%;
    }

    .text {
        top: 65px;
        padding: 0;
    }

    .text_data {
        flex-direction: column;
        gap: 15px;
        background-color: #232323E5;
        width: 100%;
        padding: 20px;
    }

    .text_invitation {
        padding: 20px;
    }

    .text_invitation>h1 {
        font-size: 48px;
        position: absolute;
        left: 200px;
        top: -50px;
    }

    .main_about_us {
        margin: 100px 0 700px 0;
    }
}

@media screen and (min-width:601px) and (max-width:800px) {
    .back {
        width: 1100px;
        height: 800px;
        padding: 25px;
    }

    .text {
        top: 65px;
    }

}


@media screen and (max-width:480px) {
    .back {
        height: 1250px;
        width: 100%;
    }

    .text_invitation>h1 {
        font-size: 48px;
        left: 50%;
        margin-left: -70px;
    }

    .main_about_us {
        margin: 40px 0 900px 0;
    }

    .text_invitation_content> p{
        font-size: 18px;
    }

    .special{
        font-size: 14px;
    }

    .text_invitation{
        padding: 10px;
    }
}



@keyframes centre {
    0% {
        box-shadow: 0 0 5px #19CE15;
    }
    50% {
        box-shadow: 0 0 30px #19CE15;
    }
    100% {
        box-shadow: 0 0 5px #19CE15;
    }
}
