* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
}

.App {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

@font-face {
  font-family: 'global-font';
  src: url(../src/font_folder/SHK_Dzeragir.otf);
}